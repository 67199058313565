import React, { useState, useMemo } from 'react';
import classes from './styles/Form.module.css';

const SEND_EMAIL_API =
  'https://hm6ftj7kjb.execute-api.us-east-2.amazonaws.com/develop';

function submitForm({ name, email: senderEmail, phone, text: emailBody }) {
  return fetch(SEND_EMAIL_API, {
    method: 'post',
    headers: new Headers({
      'Content-Type': 'application/json;charset=utf-8',
    }),
    body: JSON.stringify({ name, senderEmail, phone, emailBody }),
  });
}

export default function Form() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [text, setText] = useState('');

  const [sent, setSent] = useState(false);

  const validForm = useMemo(
    () =>
      [
        !!name,
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
          email
        ),
        /^[0-9-]{7,}[0-9]$/.test(phone),
        !!text,
      ].every((e) => e),
    [name, email, phone, text]
  );

  const validateForm = (event) => {
    event.preventDefault();
    console.log(event.target.reset);
    if (!validForm) {
      event.stopPropagation();
      return;
    }
    submitForm({ name, email, phone, text })
      .then((response) => {
        console.info(response);
        setName('');
        setEmail('');
        setPhone('');
        setText('');
        setSent(true);
        event.target.reset();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <form className={classes.form} onSubmit={validateForm}>
      <h1>Escríbanos y lo contactaremos lo antes posible</h1>
      <input
        required
        className={classes.inputText}
        type="text"
        placeholder="Su nombre"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <input
        required
        className={classes.inputText}
        type="email"
        placeholder="Su correo"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <input
        required
        className={classes.inputText}
        type="text"
        placeholder="Su teléfono"
        value={phone}
        onChange={(event) => setPhone(event.target.value)}
      />
      <textarea
        required
        rows="4"
        className={classes.inputText}
        placeholder="¿Cómo lo podemos ayudar?"
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <button
        disabled={!validForm}
        type="submit"
        className={`${classes.submitButton} ${classes.inputText}`}
      >
        {sent ? '¡Solicitud enviada!' : 'Quiero que me contacten'}
      </button>
    </form>
  );
}
