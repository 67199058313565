import React from 'react';
import classes from './styles/Header.module.css';
import { ReactComponent as Logo } from '../assets/dclimafreshlogo.svg';

export default function Header({ onContact, onStatements }) {
  return (
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <Logo
          viewBox="0 0 951.47 446.616"
          preserveAspectRatio="xMidYMid slice"
          className={classes.logo}
        />
      </div>
      <div className={classes.spacer} />
      <div className={classes.navContainer}>
        <div className={classes.navLink} onClick={onStatements}>
          Quiénes somos
        </div>
        <div className={classes.navLink} onClick={onContact}>
          <b>Contáctenos</b>
        </div>
      </div>
    </div>
  );
}
