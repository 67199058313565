import React, { useRef } from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Statements from './components/Statements';
import Footer from './components/Footer';

function scrollToRef(ref) {
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function App() {
  const heroRef = useRef(null);
  const statementsRef = useRef(null);
  return (
    <div>
      <Header
        onContact={() => scrollToRef(heroRef)}
        onStatements={() => scrollToRef(statementsRef)}
      />
      <div ref={heroRef}>
        <Hero />
      </div>
      <div ref={statementsRef}>
        <Statements />
      </div>
      <Footer />
    </div>
  );
}

export default App;
