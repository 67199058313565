import React from 'react';
import classes from './styles/Statements.module.css';
import Statement from './Statement';

export default function Statements() {
  return (
    <div className={classes.container}>
      <Statement
        fadeDirection="left"
        backgroundSrc="https://images.unsplash.com/photo-1527738697320-513f6648bc26?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3151&q=80"
        backgroundColor="#006775"
      >
        <div className={classes.statementContent}>
          <h1 className={classes.header}>Quiénes somos</h1>
          <p>
            Somos una empresa con más de 8 años de servicio en el mercado.
            Nuestros técnicos poseen una vasta experiencia y una clientela que
            avala nuestro trabajo.
          </p>
          <p>
            Ofrecemos servicios integrales en refrigeración, climatización y
            electricidad, con operación en la ciudad de Santiago.
          </p>
          <p>
            Trabajamos bajo altos parámetros de seriedad, responsabilidad y
            confiabilidad.
          </p>
        </div>
      </Statement>
      <Statement
        fadeDirection="right"
        backgroundSrc="https://images.unsplash.com/photo-1569134026163-4de371889697?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3034&q=80"
        backgroundColor="#035DA8"
      >
        <div className={classes.statementContent}>
          <h1 className={classes.header}>Qué ofrecemos</h1>
          <p>
            Instalación de equipos de Aire acondicionado de diversas capacidades
            y modelos.
          </p>
          <p>
            Dar precios acordes con el mercado o incluso mejorados para nuestros
            trabajos.
          </p>
          <p>
            Localizar y adquirir los mejores equipos disponibles en el mercado
            según su necesidad.
          </p>
          <p>
            Mantención programada de cada equipo instalado por D'ClimaFresh,
            realizando seguimiento de estos periódicamente, para dar larga vida
            útil a sus equipos.
          </p>
          <p>
            Mantención de equipos instalados anteriormente por otras empresas y
            llevar registro permanente de cada uno de ellos, para procurar su
            mejor funcionamiento.
          </p>
          <p>
            Reparación de equipos antiguos evitando la pérdida de estos, ya que
            tienen alto costo.
          </p>
        </div>
      </Statement>
      <Statement
        fadeDirection="left"
        backgroundSrc="https://images.unsplash.com/photo-1545649311-24d0ac00ae82?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3034&q=80"
        backgroundColor="rgb(166, 20, 17)"
      >
        <div className={classes.statementContent}>
          <h1 className={classes.header}>Qué buscamos</h1>
          <p>
            Nuestro objetivo es permitirle contar con un ambiente grato de
            trabajo sin preocupación ni la necesidad de buscar soluciones a
            problemas con otras empresas, cada vez que ellos se presenten.
            Solamente debe llamarnos y ahí estaremos para dar solución a su
            requerimiento.
          </p>
        </div>
      </Statement>
    </div>
  );
}
