import React from 'react';
import classes from './styles/Footer.module.css';

export default function Footer() {
  return (
    <div className={classes.container}>
      <div className={classes.footerText}>
        <ul>
          <li>
            <b>Correo</b>: climafresh1@gmail.com
          </li>
          <li>
            <b>Teléfonos</b>: +56 9 9612 0819, +56 9 4233 3796
          </li>
        </ul>
        <p>© 2020-2022 D'ClimaFresh. Todos los derechos reservados</p>
      </div>
    </div>
  );
}
