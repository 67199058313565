import React from 'react';
import classes from './styles/Statement.module.css';

export default function Statement(props) {
  const fade = {
    left: 'fadeLeft',
    right: 'fadeRight',
  }[props.fadeDirection];
  const fadeClass = classes[fade] || '';
  const contentClass = fade ? classes[`statementContent__${fade}`] : '';
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
      }}
    >
      <div
        className={`${classes.image} ${fadeClass}`}
        style={{
          backgroundImage: `url(${props.backgroundSrc})`,
        }}
      />
      <div className={contentClass}>{props.children}</div>
    </div>
  );
}
